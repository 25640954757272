import React, { useEffect } from 'react'
import { Router } from '@reach/router'

import UpdatePassword from './components/login/update'
import ForgotPassword from './components/login/forgot'
import Authenticating from './components/login/authenticating'
import Login from './components/login'
import ProtectedApp from './protected-app'
import { useAuth } from './auth'

import ReactGA from 'react-ga'

function App() {
  const { isAuthenticating, isAuthenticated, user, reAuth } = useAuth()
  const trackingId = 'UA-144496514-6' // Replace with your Google Analytics tracking ID
  ReactGA.initialize(trackingId)
  ReactGA.set({
    userId: isAuthenticated && user && user.id ? user.id : {},
    // any data that is relevant to the user session
    // that you would like to track with google analytics
  })

  useEffect(() => {
    reAuth()
  }, [reAuth])

  if (isAuthenticating) return <Authenticating />
  return (
    <div className="App">
      <Router>
        <Login default />
        {isAuthenticated && user && <ProtectedApp user={user} path="/*" />}
        <UpdatePassword path="/reset-password/:hash" />
        <ForgotPassword path="/forgot-password" />
      </Router>
    </div>
  )
}

export default App
