import React from 'react'
import PropTypes from 'prop-types'
import {
  Font,
  StyleSheet,
  Document,
  Page,
  View,
  Text,
  Image,
} from '@react-pdf/renderer'

import logo from '../../assets/pinpoint2.jpg'

import ProximaNovaAlt from '../../assets/fonts/ProximaNovaAlt.ttf'
import ProximaNovaAltSemi from '../../assets/fonts/ProximaNovaAlt-Semi.ttf'
import ProximaNovaAltBold from '../../assets/fonts/ProximaNovaAlt-Bold.ttf'

Font.register({
  family: 'ProximaNovaAlt',
  fonts: [
    { src: ProximaNovaAlt },
    { src: ProximaNovaAltSemi, fontWeight: 600 },
    { src: ProximaNovaAltBold, fontWeight: 700 },
  ],
})

const styles = StyleSheet.create({
  semi: {
    fontWeight: 600,
  },
  label: {
    fontWeight: 600,
    marginTop: 1,
    fontSize: 8,
    padding: 4,
    lineHeight: 1,
    width: '100%',
    textAlign: 'right',
    alignItems: 'center',
    display: 'flex',
    minHeight: '18px',
  },
  paye: {
    fontWeight: 600,
    marginTop: 14,
    fontSize: 8,
    padding: 4,
    lineHeight: 1,
    width: '100%',
    textAlign: 'right',
    alignItems: 'center',
    display: 'flex',
    minHeight: '18px',
  },
  tableHead: {
    fontWeight: 600,
    fontSize: 8,
    padding: 1,
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  hidden: {
    fontWeight: 600,
    fontSize: 8,
    padding: 1,
    width: '100%',
    textAlign: 'center',
    alignItems: 'center',
    display: 'flex',
    color: '#F2F6FE',
  },
  bold: {
    fontWeight: 700,
  },
  textSmall: {
    fontSize: 8,
  },
  body: {
    fontFamily: 'ProximaNovaAlt',
    fontSize: 13,
  },
  header: {
    general: {
      padding: '12px 24px',
    },
    logo: {
      width: '106px',
      marginBottom: '20px',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  main: {
    general1: {
      backgroundColor: '#F2F6FE',
      padding: '12px 15px',
    },
    general2: {
      padding: '12px 15px',
    },
    content: {
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    section: {
      flexBasis: '24%',
      fontSize: 9,
    },
    sectionSmall: {
      flexBasis: '30%',
      fontSize: 9,
      marginRight: '5px',
    },
    sectionHalf: {
      flexBasis: '48%',
      fontSize: 9,
    },
    sectionQuarter: {
      flexBasis: '25%',
      fontSize: 9,
    },
    subSectionHalf: {
      width: '47%',
    },
    title: {
      width: '100%',
      flex: '1',
      marginBottom: 4,
    },
    subTitle: {
      width: '100%',
      flex: '1',
      marginLeft: '10px',
      fontSize: 12,
    },
    box: {
      marginTop: 3,
      backgroundColor: '#ffffff',
      fontSize: 8,
      borderWidth: 1,
      borderColor: '#DEDEDE',
      padding: 7,
      minHeight: 50,
      width: '100%',
    },
    boxSmall: {
      marginTop: 1,
      backgroundColor: '#ffffff',
      fontSize: 8,
      lineHeight: 1,
      borderWidth: 1,
      borderColor: '#DEDEDE',
      padding: 4,
      width: '100%',
      minHeight: '18px',
      alignItems: 'center',
      display: 'flex',
    },
    boxHalf: {
      marginTop: 1,
      backgroundColor: '#ffffff',
      fontSize: 8,
      lineHeight: 1,
      borderWidth: 1,
      borderColor: '#DEDEDE',
      padding: 4,
      width: '50%',
      minHeight: '18px',
      alignItems: 'center',
      display: 'flex',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 3,
    },
    newline: {
      width: '100%',
      flexDirection: 'row',
    },
  },
  table: {
    head: {
      display: 'flex',
      alignItems: 'flex-end',
      fontSize: 7,
      fontWeight: 500,
      marginTop: 2,
      marginBottom: 1,
      textAlign: 'center',
      padding: 2,
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 1,
      width: '100%',
    },
    thin: {
      width: '5%',
    },
    medium: {
      width: '23%',
    },
  },
})

// Payslips view
const P60PDF = ({ data }) => {
  const toMoney = value => {
    try {
      const money = value.toLocaleString('en-UK', {
        style: 'currency',
        currency: 'GBP',
      })
      return money
    } catch (e) {
      return 'Invalid amount'
    }
  }
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.header.general}>
          <Image src={logo} style={styles.header.logo} />
          <View style={styles.header.row}>
            <Text>
              <Text style={styles.semi}>Name: </Text>
              <Text>{data.forename1 + ' ' + data.surname}</Text>
            </Text>
            <Text>
              <Text style={styles.semi}>End of Year Certificate P60</Text>
            </Text>
            <Text>
              <Text style={styles.semi}>Tax year to 5th April: </Text>
              <Text>{data.year}</Text>
            </Text>
          </View>
        </View>

        <View style={styles.main.general1}>
          <Text style={styles.main.title}>Employer&apos;s Details</Text>
          <View style={styles.main.content}>
            <View style={styles.main.row}>
              <View style={styles.main.sectionHalf}>
                <View style={styles.main.row}>
                  <View style={styles.main.sectionHalf}>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>Tax year to 5th April: </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>Employer Name: </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.paye}>Employer PAYE reference: </Text>
                    </View>
                  </View>
                  <View style={styles.main.sectionHalf}>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxSmall}>{data.year}</Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxSmall}>
                        {/* {data.company_name} */}
                        Nova Payroll Management Services Ltd trading as Pin
                        Point Recruitment
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxSmall}>
                        {'475/' + data.tax_ref}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.main.sectionHalf}>
                <View style={styles.main.row}>
                  <View style={styles.main.sectionHalf}>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>
                        Employer&apos;s address:{' '}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.main.sectionHalf}>
                    <View style={styles.main.row}>
                      <View style={styles.main.box}>
                        <Text>{data.company_addr1}</Text>
                        <Text>{data.company_addr2}</Text>
                        <Text>{data.company_addr3}</Text>
                        <Text>{data.company_addr4}</Text>
                        {/* <Text>Axwell House, Waterside Drive</Text>
                        <Text>Gateshead</Text>
                        <Text>Tyne and Wear</Text>
                        <Text>NE11 9HU</Text> */}
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.main.general2}>
          <View style={styles.main.row}>
            <Text style={styles.main.title}>Employee&apos;s Details</Text>
          </View>
          <View style={styles.main.content}>
            <View style={styles.main.row}>
              <View style={styles.main.sectionHalf}>
                <View style={styles.main.row}>
                  <View style={styles.main.sectionHalf}>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>Surname: </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>Forenames or initials: </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>
                        National Insurance number:{' '}
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>Works / payroll number: </Text>
                    </View>
                  </View>
                  <View style={styles.main.sectionHalf}>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxSmall}>{data.surname}</Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxSmall}>
                        {data.forename1 + ', ' + data.forename2}
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxSmall}>{data.ni_number}</Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxSmall}>
                        {data.refno + data.year}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View style={styles.main.sectionHalf}>
                <View style={styles.main.row}>
                  <View style={styles.main.sectionHalf}>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>
                        Employee&apos;s address:{' '}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.main.sectionHalf}>
                    <View style={styles.main.row}>
                      <View style={styles.main.box}>
                        <Text>{data.employee_address1}</Text>
                        <Text>{data.employee_address2}</Text>
                        <Text>{data.employee_address3}</Text>
                        <Text>{data.employee_address4}</Text>
                        <Text>{data.employee_address5}</Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.main.general1}>
          <View style={styles.main.row}>
            <Text style={styles.main.title}>Pay &amp; Income Tax Details</Text>
            <Text style={styles.main.title}>Statutory Payments</Text>
          </View>
          <View style={styles.main.content}>
            <View style={styles.main.row}>
              <View style={styles.main.sectionHalf}>
                <View style={styles.main.row}>
                  <View style={styles.main.sectionHalf}>
                    <View style={styles.main.row}>
                      <Text style={styles.hidden}>Type:</Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>
                        In previous employment(s):
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>In this employment*:</Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>Total for year:</Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>Final tax code:</Text>
                    </View>
                  </View>
                  <View style={styles.main.sectionQuarter}>
                    <View style={styles.main.row}>
                      <Text style={styles.tableHead}>Pay</Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxSmall}>
                        {toMoney(data.pay_previous_employment)}
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxSmall}>
                        {toMoney(data.pay_in_this_employment)}
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxSmall}>
                        {toMoney(data.pay_to_date)}
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxSmall}>{data.tax_code}</Text>
                    </View>
                  </View>
                  <View style={styles.main.sectionQuarter}>
                    <View style={styles.main.row}>
                      <Text style={styles.tableHead}>Tax deducted</Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxSmall}>
                        {toMoney(data.tax_previous_employment)}
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxSmall}>
                        {toMoney(data.tax_in_this_employment)}
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxSmall}>
                        {toMoney(data.tax_to_date)}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.main.row}>
                  <Text style={styles.textSmall}>
                    *figures shown here be used for your tax return, if you get
                    one.
                  </Text>
                </View>
              </View>
              <View style={styles.main.sectionHalf}>
                <View style={styles.main.row}>
                  <View style={styles.main.sectionHalf}>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>Statutory Maternity Pay:</Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>Statutory Paternity Pay:</Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>
                        Statutory Shared Parental Pay:
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>Statutory Adoption Pay:</Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>
                        Statutory Parental Bereavement Pay:
                      </Text>
                    </View>
                  </View>
                  <View style={styles.main.sectionHalf}>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxHalf}>
                        {data.smp_maternity}
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxHalf}>
                        {data.spp_paternity}
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxHalf}>
                        {data.shpp_shared_parental}
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxHalf}>
                        {data.sap_adoption}
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxHalf}>
                        {data.spbp_bereavement}
                      </Text>
                    </View>
                  </View>
                </View>
                <View style={styles.main.row}>
                  <Text style={styles.main.subTitle}>Other details</Text>
                </View>
                <View style={styles.main.row}>
                  <View style={styles.main.sectionHalf}>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>Student Loan deductions:</Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.label}>
                        Postgraduate Loan deductions:
                      </Text>
                    </View>
                  </View>
                  <View style={styles.main.sectionHalf}>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxHalf}>
                        {data.student_loan}
                      </Text>
                    </View>
                    <View style={styles.main.row}>
                      <Text style={styles.main.boxHalf}>
                        {data.postgraduate_loan}
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.main.general2}>
          <Text style={styles.main.title}>
            National Insurance Contributions
          </Text>
          <View>
            <View style={styles.table.row}>
              <View style={styles.table.thin}>
                <Text style={styles.table.head}>NIC table letter</Text>
              </View>
              <View style={styles.table.medium}>
                <Text style={styles.table.head}>
                  Earnings at the Lower Earnings Limit (LEL) (where earnings are
                  equal to or exceed the LEL)
                </Text>
              </View>
              <View style={styles.table.medium}>
                <Text style={styles.table.head}>
                  Earnings above the LEL, up to and including the Primary
                  Threshold (PT)
                </Text>
              </View>
              <View style={styles.table.medium}>
                <Text style={styles.table.head}>
                  Earnings above the PT, up to and including the Upper Earnings
                  Limit (UEL)
                </Text>
              </View>
              <View style={styles.table.medium}>
                <Text style={styles.table.head}>
                  Employee&apos;s contributions due on all earnings above the PT
                </Text>
              </View>
            </View>
            <View style={styles.table.row}>
              <View style={styles.table.thin}>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>
                    {data.ni_category_code1}
                  </Text>
                </View>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>
                    {data.ni_category_code2}
                  </Text>
                </View>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>
                    {data.ni_category_code3}
                  </Text>
                </View>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>
                    {data.ni_category_code4}
                  </Text>
                </View>
              </View>
              <View style={styles.table.medium}>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>{data.up_to_lel1}</Text>
                </View>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>{data.up_to_lel2}</Text>
                </View>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>{data.up_to_lel3}</Text>
                </View>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>{data.up_to_lel4}</Text>
                </View>
              </View>
              <View style={styles.table.medium}>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>{data.up_to_thresh1}</Text>
                </View>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>{data.up_to_thresh2}</Text>
                </View>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>{data.up_to_thresh3}</Text>
                </View>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>{data.up_to_thresh4}</Text>
                </View>
              </View>
              <View style={styles.table.medium}>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>{data.up_to_uel1}</Text>
                </View>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>{data.up_to_uel2}</Text>
                </View>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>{data.up_to_uel3}</Text>
                </View>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>{data.up_to_uel4}</Text>
                </View>
              </View>
              <View style={styles.table.medium}>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>{data.ees_ni1}</Text>
                </View>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>{data.ees_ni2}</Text>
                </View>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>{data.ees_ni3}</Text>
                </View>
                <View style={styles.main.row}>
                  <Text style={styles.main.boxSmall}>{data.ees_ni4}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.main.general2}>
          <View style={styles.main.row}>
            <Text style={styles.main.title}>
              Certificate by Employer/Paying Office
            </Text>
          </View>
          <View style={styles.main.content}>
            <View>
              <View style={styles.main.newline}>
                <Text style={styles.textSmall}>
                  This form shows your total pay for Income Tax purposes in this
                  employment for the year.
                </Text>
              </View>
              <View style={styles.main.newline}>
                <Text style={styles.textSmall}>
                  Any overtime, bonus, commission etc, Statutory Sick Pay,
                  Statutory Maternity Pay, Statutory Paternity Pay, Statutory
                  Shared Paternity Pay or Statutory Adoption Pay is included.
                </Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

P60PDF.propTypes = {
  data: PropTypes.object.isRequired,
}

export default P60PDF
