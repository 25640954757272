import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { ThemeProvider } from 'styled-components'

import { Toaster } from './ui'
import { AuthProvider } from './auth'
import theme from './theme'

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Toaster>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Toaster>
  </ThemeProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
