import React from 'react'
import PropTypes from 'prop-types'

import { Table, ViewLink, ViewIcon, ViewBtn } from '../../ui'

const Holding = ({ holding }) => (
  <Table>
    <thead>
      <tr>
        <th>Full name</th>
        <th data-tablet>Employee ID</th>
        <th data-tablet>NI Number</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {holding.map(({ id, reference_numbers, name, NI_no }) => (
        <tr key={id}>
          <td>{name}</td>
          <td data-tablet>
            {reference_numbers
              ? reference_numbers.map(ref => (
                  <span key={ref}>
                    #{ref}
                    <br />
                  </span>
                ))
              : '#'}
          </td>
          <td data-tablet>{NI_no}</td>

          <td>
            <ViewLink to={`/holding/${id}`}>
              <ViewIcon data-mobile data-tablet />
              <ViewBtn data-web>View</ViewBtn>
            </ViewLink>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
)

Holding.propTypes = {
  holding: PropTypes.array.isRequired,
}

export default Holding
