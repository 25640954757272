import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {} from 'date-fns'

import { Title } from '../../ui'

const Head = styled.header`
  display: flex;
  flex-wrap: wrap;

  /* mobile */
  flex-direction: column;
  margin-top: 6px;
  margin-bottom: 38px;

  h1 {
    margin-bottom: 20px;
  }

  h1,
  span {
    white-space: nowrap;
    margin-right: 1rem;
  }

  ${p => p.theme.layout.tablet} {
    flex-direction: row;
    align-items: baseline;
  }
`

const Header = ({ user }) => {
  return (
    <Head>
      <Title>{user.name}</Title>
    </Head>
  )
}

Header.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    created_at: PropTypes.string,
    leaving: PropTypes.string,
    updated_at: PropTypes.string,
  }).isRequired,
}
export default Header
