import React from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'
import styled from 'styled-components'

import { ReactComponent as DashIcon } from '../../assets/icons/dashboard.svg'
import { ReactComponent as WorkerIcon } from '../../assets/icons/worker.svg'
import { ReactComponent as HoldingIcon } from '../../assets/icons/holding.svg'
import { ReactComponent as PayslipIcon } from '../../assets/icons/payslip.svg'
import { ReactComponent as SettingsIcon } from '../../assets/icons/settings.svg'

const Nav = styled.nav`
  /* defaults */
  position: fixed;
  display: flex;
  background: white;
  left: 0;
  z-index: 2;

  /* mobile */
  bottom: 0;
  width: 100%;
  height: ${p => p.theme.layout.nav_mobile_height};
  justify-content: space-between;
  box-shadow: ${p => p.theme.style.nav_and_header_shadow};

  /*  tablet */
  ${p => p.theme.layout.tablet} {
    flex-direction: column;
    width: ${p => p.theme.layout.nav_tablet_width};
    top: ${p => p.theme.layout.header_height};
    padding-top: 2rem;
    bottom: 0;
    height: unset;
    justify-content: flex-start;
    box-shadow: none;
  }

  /* web */
  ${p => p.theme.layout.web} {
    width: ${p => p.theme.layout.nav_web_width};
  }
`

const SLink = styled(Link)`
  /* defaults */
  flex: 1;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: black;
  transition: border 200ms ease-in-out, background 200ms ease-in-out;
  &[data-active='true'] {
    background: ${p => p.theme.color.bg};
  }

  /* mobile */
  border-top: ${p => p.theme.utils.nav_border('transparent')};
  justify-content: center;
  &[data-active='true'] {
    border-top: ${p => p.theme.utils.nav_border()};
  }

  /* tablet */
  ${p => p.theme.layout.tablet} {
    max-height: ${p => p.theme.layout.nav_web_link_height};
    border-top: none !important;
    border-left: ${p => p.theme.utils.nav_border('transparent')};
    justify-content: flex-start;
    &[data-active='true'] {
      border-left: ${p => p.theme.utils.nav_border()};
    }
  }
`
const LinkText = styled.span`
  display: none;
  ${p => p.theme.layout.web} {
    display: inline-block;
  }
`

const styleIcon = icon => {
  const Icon = styled(icon)`
    height: 25px;
    width: 25px;
    stroke: ${p => p.theme.color.primary};
    fill: ${p => p.theme.color.primary};
    ${p => p.theme.layout.tablet} {
      margin-left: 1.5rem;
      margin-right: 1rem;
    }
  `
  return <Icon />
}

const nav_links = {
  super_admin: [
    {
      route: '/',
      name: 'Dashboard',
      match: 'isCurrent',
      icon: DashIcon,
    },
    {
      route: '/workers',
      name: 'Workers',
      match: 'isPartiallyCurrent',
      icon: WorkerIcon,
    },
    {
      route: '/holding',
      name: 'Holding',
      match: 'isPartiallyCurrent',
      icon: HoldingIcon,
    },
    {
      route: '/payslips',
      name: 'Payslips',
      match: 'isPartiallyCurrent',
      icon: PayslipIcon,
    },
    {
      route: '/settings',
      name: 'Settings',
      match: 'isPartiallyCurrent',
      icon: SettingsIcon,
    },
  ],
  is_admin: [
    {
      route: '/',
      name: 'Dashboard',
      match: 'isCurrent',
      icon: DashIcon,
    },
    {
      route: '/workers',
      name: 'Workers',
      match: 'isPartiallyCurrent',
      icon: WorkerIcon,
    },
    {
      route: '/holding',
      name: 'Holding',
      match: 'isPartiallyCurrent',
      icon: HoldingIcon,
    },
    {
      route: '/payslips',
      name: 'Payslips',
      match: 'isPartiallyCurrent',
      icon: PayslipIcon,
    },
    {
      route: '/settings',
      name: 'Settings',
      match: 'isPartiallyCurrent',
      icon: SettingsIcon,
    },
  ],
}

// Reach Router: getProps func for styling active link
// adds data-active attribute when link matches isCurrent/isPartiallyCurrent type
const isActive = type => props => ({ 'data-active': props[type] })

const NavContainer = ({ userType }) => {
  const links = nav_links[userType]
  return (
    <Nav>
      {links.map(({ route, name, match, icon }) => (
        <SLink
          key={name}
          to={route}
          getProps={isActive(match)}
          title={name}
          aria-label={name}
        >
          {styleIcon(icon)}
          <LinkText>{name}</LinkText>
        </SLink>
      ))}
    </Nav>
  )
}

NavContainer.propTypes = {
  userType: PropTypes.oneOf(['is_admin', 'super_admin']).isRequired,
}
export default NavContainer
