import styled from 'styled-components'

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  ${p => p.theme.style.box_shadow};
  padding: 24px 20px;
  margin-bottom: 15px;
`

export { Box }
