import React from 'react'
import styled from 'styled-components'

const styleIcon = (icon, size = '25px') => {
  const Icon = styled(icon)`
    min-height: ${size};
    min-width: ${size};
    height: ${size};
    width: ${size};
    stroke: ${p => p.theme.color.primary};
  `
  return <Icon />
}

export default styleIcon
