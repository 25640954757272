import styled from 'styled-components'
import { Link } from '@reach/router'

import { ReactComponent as ArrowIcon } from '../assets/icons/arrow.svg'

const Table = styled.table`
  width: 100%;
  border-spacing: 0 15px;
  margin-bottom: 26px;
  font-size: 18px;

  ${p => p.theme.layout.tablet} {
    border-spacing: 0 8px;
    margin-bottom: 50px;
    font-size: 15px;
  }

  thead {
    th {
      color: ${p => p.theme.color.text};
      letter-spacing: 1px;
      text-transform: uppercase;
      font-size: 15px;
      text-align: left;

      &:first-of-type {
        padding-left: 20px;
      }
      ${p => p.theme.layout.tablet} {
        font-size: 13px;
        &:first-of-type {
          padding-left: 30px;
        }
      }

      ${p => p.theme.utils.tablet(`display: table-cell`)}
    }
  }

  tbody {
    tr {
      background: white;
      ${p => p.theme.style.box_shadow};
    }
    td {
      padding: 15px 20px 15px 0;
      &:first-of-type {
        padding-left: 20px;
      }
      /* tablet */
      ${p => p.theme.layout.tablet} {
        padding-right: 30px;
        &:first-of-type {
          padding-left: 30px;
        }
      }

      ${p => p.theme.utils.tablet(`display: table-cell`)}
    }
  }
`

const StatusIndicator = styled.span`
  display: inline-block;
  height: 16px;
  width: 16px;
  border-radius: 16px;
  vertical-align: middle;
  background: ${p => (p.active ? p.theme.color.success : p.theme.color.fail)};
  margin-right: 0.5rem;
`
const StatusText = styled.span`
  ${p => p.theme.utils.web(`display: inline-block`)}
`

const ViewLink = styled(Link)`
  /* default */
  display: flex;
  background: none;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin-left: auto;
  text-decoration: none;
  border: none;
  cursor: pointer;

  [data-mobile],
  [data-tablet],
  [data-web] {
    display: none;
  }

  [data-mobile] {
    display: inline-block;
    ${p => p.theme.layout.tablet} {
      display: none;
    }
    ${p => p.theme.layout.web} {
      display: none;
    }
  }

  [data-tablet] {
    ${p => p.theme.layout.tablet} {
      display: inline-block;
    }
    ${p => p.theme.layout.web} {
      display: none;
    }
  }

  [data-web] {
    ${p => p.theme.layout.web} {
      display: inline-block;
    }
  }
`
const ViewIcon = styled(ArrowIcon)`
  width: 25px;
  height: 25px;
  stroke: none;
  transform: rotateZ(-90deg);
  fill: ${p => p.theme.color.primary};
`
const ViewBtn = styled.div`
  display: inline-block;
  background: ${p => p.theme.style.primary_gradient};
  border-radius: 27px;
  font-size: 15px;
  padding: 11px 2rem;
  color: white;
  height: 40px;
  margin-left: auto;
  cursor: pointer;
`

export { Table, StatusIndicator, StatusText, ViewLink, ViewIcon, ViewBtn }
