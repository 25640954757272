import React from 'react'
import PropTypes from 'prop-types'

import { getShortMonth } from '../../utilities'
import { Table } from '../../ui'
import Payslip from '../payslips/payslip'

const MonthlyPayslipsList = ({ payslips, month, showPayslips }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Month</th>
          <th>Payslip</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {payslips.map((p, i) => (
          <tr key={p}>
            <td>{getShortMonth(2020, month)}</td>
            <td>{i + 1}</td>
            <td style={{ textAlign: 'right' }}>
              {showPayslips ? <Payslip id={p} /> : null}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

MonthlyPayslipsList.propTypes = {
  payslips: PropTypes.array.isRequired,
  month: PropTypes.string.isRequired,
  showPayslips: PropTypes.bool.isRequired,
}
export default MonthlyPayslipsList
