import React from 'react'
import PropTypes from 'prop-types'

import Payslip from './payslip'
import { getShortMonth } from '../../utilities'
import { Table } from '../../ui'

const payslips = ({ payslips, user }) => (
  <Table>
    <thead>
      <tr>
        <th>Full name</th>
        <th data-tablet>Employee ID</th>
        <th>Month</th>
        <th data-tablet>Gross Pay (month)</th>
        <th data-tablet>Net Wage (month)</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {payslips.map(({ id, ref_no, name, date, taxable_pay, net_payment }) => (
        <tr key={id}>
          <td>{name}</td>
          <td data-tablet>{ref_no ? `#${ref_no}` : ''}</td>
          <td>
            {getShortMonth(
              new Date(date).getYear(),
              new Date(date).getMonth() + 1,
            )}
          </td>
          <td data-tablet>£{parseFloat(taxable_pay).toFixed(2)}</td>
          <td data-tablet>£{parseFloat(net_payment).toFixed(2)}</td>
          <td style={{ textAlign: 'right ' }}>
            {user.id === parseInt(id) || user.super_admin ? (
              <Payslip id={id} />
            ) : null}
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
)

payslips.propTypes = {
  payslips: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
}

export default payslips
