import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Label = styled.label`
  text-transform: uppercase;
  font-size: 15px;
  color: ${p => p.theme.color.text};
  letter-spacing: 1px;
  display: inline-block;
  margin-bottom: 0.5rem;
`

const InputContainer = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
`

const StyledInput = styled.input`
  height: 54px;
  width: 100%;
  font-size: 1rem;
  padding: 0 1rem 0 ${p => (p.icon ? 'calc(1.5rem + 22px)' : '1rem')};
  border: 1px solid ${p => p.theme.color.border};
  border-radius: 0;
  appearance: none;
  &:disabled {
    color: ${p => p.theme.color.disabled};
  }
`

const styleIcon = icon => {
  const StyledIcon = styled(icon)`
    position: absolute;
    left: 1rem;
    height: 22px;
    bottom: calc((100% - 22px) / 2);
    fill: ${p => p.theme.color.primary};
  `
  return <StyledIcon />
}

const Input = ({ label, icon, ...props }) => (
  <>
    {label && <Label htmlFor={props.name}>{label}</Label>}
    <InputContainer icon={icon}>
      <StyledInput id={props.name} icon={icon} {...props} />
      {icon ? styleIcon(icon) : null}
    </InputContainer>
  </>
)

Input.defaultProps = {
  label: undefined,
  icon: undefined,
}
Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  icon: PropTypes.object,
}

const StyledTextarea = styled.textarea`
  font-size: 1rem;
  padding: 1rem;
  border: 1px solid ${p => p.theme.color.border};
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  border-radius: 0;
  appearance: none;
  resize: none;
  width: 100%;
  color: ${p => p.theme.color.text};
`

const Textarea = ({ label, ...props }) => (
  <>
    {label && <Label htmlFor={props.name}>{label}</Label>}
    <StyledTextarea id={props.name} {...props} />
  </>
)

Textarea.defaultProps = {
  label: undefined,
}
Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

export { Input, Textarea }
