import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Router, navigate } from '@reach/router'
import styled from 'styled-components'

import Header from './components/header'
import Nav from './components/nav'
import Settings from './components/settings'
import Dashboard from './components/dashboard'
import Workers from './components/workers'
import User from './components/user'
import Holding from './components/holding'
import Payslips from './components/payslips'

import ReactGA from 'react-ga'

const UserView = styled.main`
  /* default */
  position: fixed;
  overflow: scroll;
  background: ${p => p.theme.color.bg};
  top: ${p => p.theme.layout.header_height};
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;

  /* mobile */
  padding: 20px;

  /* web */
  ${p => p.theme.layout.web} {
    padding: 44px 50px;
  }
`

const AdminView = styled(UserView)`
  /* mobile */
  left: 0;
  bottom: ${p => p.theme.layout.nav_mobile_height};

  /* tablet */
  ${p => p.theme.layout.tablet} {
    left: ${p => p.theme.layout.nav_tablet_width};
    bottom: 0;
  }

  /* web */
  ${p => p.theme.layout.web} {
    left: ${p => p.theme.layout.nav_web_width};
  }
`

const SRouter = styled(Router)`
  height: 100%;
`
function ProtectedApp({ user }) {
  ReactGA.pageview(window.location.pathname + window.location.search)
  useEffect(() => {
    const not_admin = !user.super_admin && !user.is_admin
    if (user && not_admin) navigate('/')
  }, [user])

  if (!user) return null

  if (user.super_admin) {
    return (
      <>
        <Nav userType="super_admin" />
        <Header />
        <AdminView>
          <SRouter>
            <Dashboard default path="/" />
            <Workers path="workers" />
            <User path="/workers/:id" />
            <Holding path="holding" />
            <User path="holding/:id" isHolding />
            <Payslips path="payslips" />
            <Settings path="settings" />
          </SRouter>
        </AdminView>
      </>
    )
  }
  if (user.is_admin) {
    return (
      <>
        <Nav userType="is_admin" />
        <Header />
        <AdminView>
          <SRouter>
            <Dashboard default path="/" />
            <Workers path="workers" />
            <User path="/workers/:id" />
            <Holding path="holding" />
            <User path="holding/:id" isHolding />
            <Payslips path="payslips" isAdmin />
            <Settings path="settings" />
          </SRouter>
        </AdminView>
      </>
    )
  } else {
    return (
      <>
        <Header />
        <UserView>
          <User id={user.id + ''} />
        </UserView>
      </>
    )
  }
}

ProtectedApp.propTypes = {
  user: PropTypes.object.isRequired,
}

export default ProtectedApp
