import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { ReactComponent as WorkerIcon } from '../../assets/icons/worker.svg'
import { ReactComponent as PayslipIcon } from '../../assets/icons/payslip.svg'
import { styleIcon } from '../../utilities'

const Overview = styled.section`
  display: flex;

  /* mobile */
  margin-bottom: 26px;
  flex-wrap: wrap;

  /* tablet */
  ${p => p.theme.layout.tablet} {
    flex-wrap: nowrap;
    ${p => p.theme.style.box_shadow};
    width: fit-content;
    margin-bottom: 50px;
  }
`
const Box = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 14px 2rem;

  /* mobile */
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 15px;
  ${p => p.theme.style.box_shadow};

  /* tablet */
  ${p => p.theme.layout.tablet} {
    width: fit-content;
    box-shadow: none;
    margin-bottom: 0;
    &:first-of-type {
      position: relative;
      &:after {
        content: '';
        z-index: 2;
        position: absolute;
        width: 2px;
        height: 33px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background: #c1c1c1;
      }
    }
  }
`
const Col = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  ${p => p.theme.layout.tablet} {
    margin-left: 1rem;
  }
`
const OverviewText = styled.span`
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${p => p.theme.color.text};
  font-size: 15px;
  ${p => p.theme.layout.tablet} {
    font-size: 13px;
  }
`
const OverviewCount = styled.span`
  font-size: 22px;
`

const OverviewComponent = ({ workers, payslips }) => (
  <Overview>
    <Box>
      {styleIcon(WorkerIcon, '32px')}
      <Col>
        <OverviewText>Total workers</OverviewText>
        <OverviewCount>{workers}</OverviewCount>
      </Col>
    </Box>
    <Box>
      {styleIcon(PayslipIcon, '32px')}
      <Col>
        <OverviewText>Total payslips</OverviewText>
        <OverviewCount>{payslips}</OverviewCount>
      </Col>
    </Box>
  </Overview>
)

OverviewComponent.propTypes = {
  workers: PropTypes.number.isRequired,
  payslips: PropTypes.number.isRequired,
}
export default OverviewComponent
