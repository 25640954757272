import React from 'react'

import { useFetch } from '../../utilities/useFetch'
import Dashboard from './dashboard'
import Loader from '../loader'
import { useAuth } from '../../auth'

// Container to fetch data
export default () => {
  const {
    data: workers,
    error: workersError,
    isFetching: isFetchingWorkers,
  } = useFetch('/admin/workers')
  const {
    data: payslips,
    error: payslipsError,
    isFetching: isFetchingPayslips,
  } = useFetch('/admin/payslips')

  const { user } = useAuth()
  return (
    <Loader
      isFetching={isFetchingWorkers || isFetchingPayslips}
      error={workersError || payslipsError}
    >
      {workers && payslips && (
        <Dashboard
          totalWorkers={workers.total}
          totalPayslips={payslips.total}
          latestWorkers={workers.data.slice(0, 5)}
          latestPayslips={payslips.data.slice(0, 5)}
          user={user}
        />
      )}
    </Loader>
  )
}
