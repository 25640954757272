import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { ReactComponent as SVG } from '../../assets/pinpoint_logo_sm.svg'

const Container = styled.div`
  width: 100%;
  margin-bottom: 3rem;
`

const LoadingContainer = styled.div`
  display: flex;
`
export const LoadingIndicator = styled(SVG)`
  width: 50px;
  animation: spin 2s infinite ease;
  margin: auto;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const Loader = ({ isFetching, error, children }) => {
  if (error) return error
  return (
    <Container>
      {children}
      <br />
      {isFetching && (
        <LoadingContainer>
          <LoadingIndicator />
        </LoadingContainer>
      )}
    </Container>
  )
}

Loader.defaultProps = {
  children: null,
}
Loader.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  children: PropTypes.node,
}
export default Loader
