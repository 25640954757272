import React from 'react'
import PropTypes from 'prop-types'

import Overview from './overview'
import Payslip from '../payslips/payslip'
import { Title, SubTitle, Table, ViewLink, ViewIcon, ViewBtn } from '../../ui'
import { getShortMonth } from '../../utilities'

const Dashboard = ({
  totalWorkers,
  totalPayslips,
  latestWorkers,
  latestPayslips,
  user,
}) => (
  <>
    <Title>Dashboard</Title>
    <Overview workers={totalWorkers} payslips={totalPayslips} />

    <SubTitle>Latest Workers</SubTitle>
    <Table>
      <thead>
        <tr>
          <th>Full name</th>
          <th>Employee ID</th>
          <th data-tablet>Email</th>
          <th data-tablet>NI Number</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {latestWorkers.map(worker => (
          <tr key={worker.id}>
            <td>{worker.name}</td>
            <td>
              {worker.reference_numbers
                ? worker.reference_numbers.map(ref => (
                    <>
                      <span key={ref}>#{ref}</span>
                      <br />
                    </>
                  ))
                : '#'}
            </td>
            <td data-tablet>{worker.email}</td>
            <td data-tablet>{worker.NI_no}</td>
            <td style={{ textAlign: 'right' }}>
              <ViewLink to={`/workers/${worker.id}`}>
                <ViewIcon data-mobile data-tablet />
                <ViewBtn data-web>View</ViewBtn>
              </ViewLink>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>

    <SubTitle>Latest Payslips</SubTitle>
    <Table>
      <thead>
        <tr>
          <th>Full name</th>
          <th data-tablet>Employee ID</th>
          <th>Month</th>
          <th data-tablet>Gross Pay (month)</th>
          <th data-tablet>Net Wage (month)</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {latestPayslips.map(payslip => (
          <tr key={payslip.id}>
            <td>{payslip.name}</td>
            <td data-tablet>#{payslip.ref_no}</td>
            <td>
              {getShortMonth(
                new Date(payslip.date).getYear(),
                new Date(payslip.date).getMonth() + 1,
              )}
            </td>
            <td data-tablet>£{parseFloat(payslip.taxable_pay).toFixed(2)}</td>
            <td data-tablet>£{parseFloat(payslip.net_payment).toFixed(2)}</td>
            <td style={{ textAlign: 'right' }}>
              {user.id === parseInt(payslip.id) || user.super_admin ? (
                <Payslip id={payslip.id} />
              ) : null}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  </>
)

Dashboard.propTypes = {
  totalWorkers: PropTypes.number.isRequired,
  totalPayslips: PropTypes.number.isRequired,
  latestWorkers: PropTypes.array.isRequired,
  latestPayslips: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
}

export default Dashboard
