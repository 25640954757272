import React from 'react'

import { usePaginatedFetch } from '../../utilities'
import PayslipsTable from './payslips'
import SearchForm from '../search-form'
import Loader from '../loader'
import { Button } from '../../ui'
import { useAuth } from '../../auth'

// Container to fetch payslip overview data
const PayslipsContainer = () => {
  const {
    error,
    data,
    isFetching,
    search,
    fetchMore,
    hasMore,
  } = usePaginatedFetch('/admin/payslips')

  const handleSearch = query => search(query)
  const { user } = useAuth()

  return (
    <Loader isFetching={isFetching} error={error}>
      {data && (
        <>
          <SearchForm onSearch={handleSearch} title="Payslips" />
          {data.length ? (
            <>
              <PayslipsTable payslips={data} user={user} />
              {hasMore && (
                <Button center onClick={fetchMore}>
                  More
                </Button>
              )}
            </>
          ) : (
            'No search results'
          )}
        </>
      )}
    </Loader>
  )
}

export default PayslipsContainer
