import React from 'react'
import PropTypes from 'prop-types'
import FileUploader from '../file_uploader'
import { Title } from '../../ui'

const Settings = ({ onSubmit }) => {
  return (
    <>
      <Title>Settings</Title>
      <FileUploader
        onUpload={onSubmit}
        promptText="Drag & Drop your document in to Upload (File must be a .csv)"
      />
    </>
  )
}

Settings.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
export default Settings
