import React from 'react'
import styled from 'styled-components'

import { useAuth } from '../../auth'
import { ReactComponent as Logo } from '../../assets/pinpoint_logo.svg'

const Header = styled.header`
  position: fixed;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  height: ${p => p.theme.layout.header_height};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 20px 15px 20px;
  box-shadow: ${p => p.theme.style.nav_and_header_shadow};
  z-index: 3;
`

const HeaderContainer = () => {
  const { logout } = useAuth()

  return (
    <Header>
      <Logo height="48" />

      <button
        style={{ border: 'none', cursor: 'pointer', background: 'none' }}
        onClick={logout}
      >
        Logout
      </button>
    </Header>
  )
}

export default HeaderContainer
