import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`
const Page = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  max-width: 800px;
  max-height: 100vh;
  padding: 1rem;
  margin: 0;
  background: white;
  overflow: scroll;
  ${p => p.theme.style.box_shadow};
  h1 {
    margin-bottom: 1rem;
  }
  ol {
    counter-reset: item;
  }
  li {
    margin-top: 1rem;
    display: block;
  }
  li:before {
    content: counters(item, '.') ' ';
    counter-increment: item;
  }
  p {
    margin-top: 1rem;
    line-height: 1.5rem;
  }

  @media (min-width: 800px) {
    margin: auto;
    max-height: 800px;
    padding: 2rem;
  }
`

const CloseBtn = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  margin: 0;
  border: none;
  background: none;
  cursor: pointer;
`

const Terms = ({ close }) => {
  return (
    <Overlay onClick={close}>
      <Page>
        <CloseBtn type="button" onClick={close}>
          X
        </CloseBtn>
        <h1>Terms and Conditions</h1>
        <h2>User Policy</h2>
        <p>
          By using this website you (or whomever you use it on behalf of) are
          deemed to accept that this policy applies between you, as user of this
          website, and us, Burr Baxter Print Ltd, the owner and provider of this
          website, and is intended to be legally enforceable between us,
          mutually deemed to be governed by and construed in accordance with the
          Law of England and Wales (and you and we agree to submit to the
          exclusive jurisdiction of the Courts of England and Wales).
        </p>
        <p>
          We make this website available pursuant to an agreement we may have
          with your employer (or former employer), that wishes to make
          information and documents available in this manner (&quot;our
          Customer&quot;). You are not our Customer. We are engaged in the
          presentation to you of information provided to us by our Customer. If
          you have any queries either about the information or documents
          presented, or the manner in which we present them, then you should
          address those directly to our Customer (not to us).
        </p>
        <p>
          This policy is applicable to our use of any and all data collected by
          us in relation to your use of the website and any services or systems
          in or from this website.
        </p>
        <p>
          In the event that any of these terms are found to be unlawful, invalid
          or otherwise unenforceable, that term is to be deemed severed from
          this Policy and shall not affect the validity and enforceability of
          the remainder of the Policy. This term shall apply only within
          jurisdictions where a particular term is illegal.
        </p>
        <ol>
          <li>
            Collection of Data
            <p>
              To use all services and systems available on the website you may
              be required to submit secure passwords as outlined in the
              documents available on this website, and e-mail addresses.
              Whenever you use this site the following information may
              automatically be collected in relation to your access: IP address
              originating access, web browser type / version used, operating
              system used, a list of the URLs you use (starting with any
              referring site), your activity on this website, and the site you
              exit to.
            </p>
          </li>
          <li>
            Our Use of Data collected
            <ol>
              <li>
                We will store any data submitted for as long as you use the
                services and systems provided on the website, and may store it
                for an additional period of up to 3 years.
              </li>
              <li>
                Unless we are obliged or permitted by law to do so, or it is
                technically essential, and subject to the other provisions of
                this policy, your data collected will not be disclosed to third
                parties without being anonymised, first.
              </li>
              <li>
                All data is processed in accordance with the principles of the
                Data Protection legislation.
              </li>
              <li>
                Any or all data may be required by us from time to time in order
                to provide you with the best possible service and experience
                when using our website, and in particular data may be used by us
                for any of the following:
                <ol>
                  <li>internal record keeping;</li>
                  <li>improvement of our products / services;</li>
                  <li>
                    transmission by email of promotional materials that may be
                    of interest to you;
                  </li>
                  <li>
                    contact for market research purposes which may be done using
                    email. Such information may be used to customise or update
                    the website.
                  </li>
                </ol>
              </li>
              <li>
                We may, from time to time, employ the services of other parties
                for dealing with matters that may include, but are not limited
                to, payment handling, delivery of purchased items, search engine
                facilities, advertising and marketing. The providers of such
                services may be granted access to certain data. Any data used by
                such parties is used only to the extent required by them to
                perform their services for us, and any other use will be
                strictly prohibited by us. Furthermore, any data that is
                processed by such third parties will be processed within the
                terms of this Policy and in accordance with the Data Protection
                legislation.
              </li>
            </ol>
          </li>
          <li>
            Access to your Data
            <ol>
              <li>
                You may access your account at any time to view or amend your
                settable data. You may need to modify or update your data if
                your circumstances change. Additional data as to your marketing
                preferences may also be stored and you may change this at any
                time.
              </li>
              <li>
                You have the right to ask us for a copy of your personal data on
                payment of a small fee.
              </li>
            </ol>
          </li>
          <li>
            Cookies
            <ol>
              <li>
                Cookies are small text files containing an anonymous unique
                identifier, accessible only by the website that placed it there
                (and not by any other sites) which we may place on your computer
                when you visit certain parts of this website. We use cookies to
                allow us to identify recurring visitors and to analyse their
                browsing habits within the website, and (where e-commerce
                facilities are provided) cookies may be used to store your order
                and account details.
              </li>
              <li>
                By default your browser will accept cookies - however this can
                be altered; for further details please consult the help menu in
                your browser. You have the choice to enable / disable cookies in
                your web browser. Your disablement of cookies may prevent you
                from using the full range of services available on the website.
              </li>
              <li>
                You may also use your browser&apos;s settings to delete cookies
                you formerly accepted - subject to losing any information that
                enables you to access the website more quickly.
              </li>
              <li>
                Unless you have disabled or deleted them, you consent to our
                placing and accessing cookies on your computer.
              </li>
            </ol>
          </li>
          <li>
            Changes of Business Ownership and Control
            <ol>
              <li>
                We may, from time to time, expand or reduce our business and
                this may involve the sale of certain divisions or the transfer
                of control of certain divisions to other parties. Data provided
                by users will, where it is relevant to any division so
                transferred, be transferred along with that division and the new
                owner or newly controlling party will, under the terms of this
                Policy, be permitted to use the data for the purposes for which
                it was supplied by you.
              </li>
              <li>
                In the event that any data submitted by Users will be
                transferred in such a manner, you will not be contacted in
                advance and informed of the changes.
              </li>
            </ol>
          </li>
          <li>
            Changes to this Policy
            <p>
              We reserve the right to change this Policy as we may deem
              necessary from time to time or as may be required by law. Any
              changes will be immediately posted on the website and you are
              deemed to have accepted the terms of the Policy on your first use
              of the website following the alterations.
            </p>
          </li>
          <li>
            Conditions of use
            <ol>
              <li>
                Subject to any specific exceptions outlined in this policy, any
                text, logos, icons, page layouts, page layout, graphics, images,
                audio, video, software, data, data compilations and any other
                form of information capable of being stored in a computer that
                appears on or forms part of this website (&quot;Content&quot;)
                is deemed between you and us to be our property that you
                acknowledge is protected by applicable United Kingdom and
                International intellectual property and other laws.
              </li>
              <li>
                This website may only be used bona fide by private individuals
                for their own private personal purposes, and not by any other
                type of user. In particular commercial competitors of ours,
                those looking to promote goods or services to us, hackers, and
                aggregators may not use this site and must cease doing so if
                they have started to do so.
              </li>
              <li>
                Unless otherwise indicated on the website, or given express
                written permission to do so by us, and subject to re-use without
                written permission under any of the exceptions detailed in
                Chapter III of the Copyright Designs and Patents Act 1988, you
                may only print, reproduce, copy, distribute, store, or in any
                other fashion re-use, content from this site for private
                personal purposes; you may not use Content for any commercial
                purposes.
              </li>
              <li>
                In accessing this site you must not impersonate other people,
                particularly our employees and representatives, e.g. by using
                other&apos;s passwords and e-mail addresses.
              </li>
            </ol>
          </li>

          <li>
            Suspension for misuse
            <p>
              In the event that you do not observe the provisions of this policy
              we reserve the right to suspend or terminate your access to the
              service (in total). Any users banned in this way must not attempt
              to use the website under any other name or by using the access
              credentials of another user, with or without the permission of
              that user.
            </p>
          </li>

          <li>
            Restrictions
            <ol>
              <li>
                Nothing in this Policy is intended to exclude or restrict our
                liability for causing death or personal injury, or resulting
                from any negligence or fraud on our part; the remainder of this
                Policy is subject to this clause.
              </li>
              <li>
                This website may contain links to other sites. Unless expressly
                stated, these sites are not under our control and we assume no
                responsibility for the content of such web sites and disclaim
                liability for any and all forms of loss or damage arising out of
                the use of them. The inclusion of a link to another site on this
                website does not imply any endorsement of the sites themselves
                or of those in control of them.
              </li>
              <li>
                We will not be liable in any way or under any circumstances for
                any loss or damage that you may incur as a result of Content,
                nor for any errors or omissions in Content. Use of and reliance
                upon such Content is entirely at your own risk.{' '}
              </li>
              <li>
                This website is provided &quot;as is&quot; and on an &quot;as
                available&quot; basis; we give no warranty that the service will
                be free of defects and / or faults, or will be useable at all at
                any particular time. To the maximum extent permitted by the law
                we provide no warranties (express or implied) of fitness for a
                particular purpose, accuracy of information, compatibility and
                satisfactory quality. In particular we make no warranty or
                representation that the website will meet your requirements,
                that it will be compatible with all systems, that it will be
                secure, and that all information provided will be accurate, or
                that the information provided to us by our Customer is correct.
                We make no guarantee of any specific results from the use of our
                services. We especially accept no liability for any disruption
                or non-availability of the website resulting from external
                causes including, but not limited to, ISP equipment failure,
                host equipment failure, communications network failure, power
                failure, natural events, acts of war or legal restrictions and
                censorship.
              </li>
              <li>
                To the maximum extent permitted by law, we accept no liability
                for any director indirect loss or damage, foreseeable or
                otherwise, including any indirect, consequential, special or
                exemplary damages arising from the use of the website or any
                information contained therein. Users should be aware that they
                use the website and its content at their own risk.
              </li>
            </ol>
          </li>
        </ol>
      </Page>
    </Overlay>
  )
}

Terms.propTypes = {
  close: PropTypes.func.isRequired,
}
export default Terms
