import React from 'react'

import API from '../../api'
import Settings from './settings'

const Account = () => {
  const handleSubmit = (file, type) => API.uploadFile(file, type)

  return <Settings onSubmit={handleSubmit} />
}

export default Account
