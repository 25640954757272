import React from 'react'

import { ReactComponent as Logo } from '../../assets/pinpoint_logo.svg'
import { Container, Box } from './ui'
import { LoadingIndicator } from '../loader'

const Authenticating = () => {
  return (
    <Container>
      <Box>
        <Logo height="54" />
        <div style={{ margin: '50px 0' }}>
          <LoadingIndicator />
        </div>
      </Box>
    </Container>
  )
}

export default Authenticating
