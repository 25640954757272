import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { PDFDownloadLink } from '@react-pdf/renderer'
import styled, { css } from 'styled-components'
import { useToasts } from 'react-toast-notifications'

import P60PDF from './p60PDF'

const style = css`
  display: inline-block;
  background: ${p => p.theme.style.primary_gradient};
  border-radius: 27px;
  font-size: 15px;
  padding: 11px 8px;
  color: white;
  height: 40px;
  max-width: 140px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  &:hover,
  &:focus {
    background: ${p => p.theme.style.primary_gradient__active};
  }
`
const GeneratePDFButton = styled.div`
  ${style}
`
const StyledDownloadLink = styled(PDFDownloadLink)`
  ${style}
  text-decoration: none;
  background: ${p =>
    p.isReady ? p.theme.color.accent : p.theme.style.primary_gradient};
  &:hover,
  &:focus {
    background: ${p =>
      p.isReady ? '#c2243d' : p.theme.style.primary_gradient__active};
  }
`

// Fetch data for payslip pdf
const P60Container = ({ p60 }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [p60Data, setP60Data] = useState(null)
  const [isReady, setIsReady] = useState(false)
  const { addToast } = useToasts()

  async function generatePDF() {
    if (!isFetching && !isReady) {
      setIsFetching(true)
      try {
        setP60Data(p60)
      } catch (e) {
        addToast(e.message || 'Error', { appearance: 'error' })
      }
    }
  }

  return p60Data ? (
    <StyledDownloadLink
      document={<P60PDF {...p60Data} />}
      fileName={`${p60Data.uuid}.pdf`}
      isReady={isReady}
    >
      {({ loading }) => {
        setIsReady(!loading)
        return loading ? 'Generating...' : 'Download'
      }}
    </StyledDownloadLink>
  ) : (
    <GeneratePDFButton
      onClick={generatePDF}
      className="generate-payslip"
      disabled={isFetching}
    >
      {isFetching ? 'Fetching...' : 'Generate'}
    </GeneratePDFButton>
  )
}

P60Container.propTypes = {
  p60: PropTypes.object.isRequired,
}
export default P60Container
