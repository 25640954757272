import React from 'react'
import PropTypes from 'prop-types'

import { useFetch } from '../../utilities'

import PayslipSelector from './payslipSelector'
import Loader from '../loader'

const UserContainer = ({ id, referenceNumbers, p60, showPayslips }) => {
  const sortedReferenceNumbers = referenceNumbers.map(ref => ({
    value: ref,
    label: ref,
  }))

  const [selectedRef, setSelectedRef] = React.useState(
    sortedReferenceNumbers[0]?.value,
  )

  const { data, error, isFetching } = useFetch(
    `/payslips/archive/${id}`,
    '',
    selectedRef,
  )
  return (
    <Loader isFetching={isFetching} error={error}>
      {data ? (
        <PayslipSelector
          payslipsByYear={data}
          referenceNumbers={sortedReferenceNumbers}
          refNum={selectedRef}
          setRefNum={setSelectedRef}
          p60={p60}
          showPayslips={showPayslips}
        />
      ) : isFetching ? null : (
        <p>No Payslips available</p>
      )}
    </Loader>
  )
}

UserContainer.propTypes = {
  id: PropTypes.string.isRequired,
  referenceNumbers: PropTypes.array.isRequired,
  p60: PropTypes.array.isRequired,
  showPayslips: PropTypes.bool.isRequired,
}

export default UserContainer
