import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useToasts } from 'react-toast-notifications'

import { SubTitle, Input, Button, Textarea } from '../../ui'
const errorToast = { appearance: 'error' }

const DetailsPanel = styled.div`
  padding: 36px 20px;
  background: white;
  ${p => p.theme.style.box_shadow};

  button {
    height: 54px;
    font-size: 15px;
  }

  ${p => p.theme.layout.tablet} {
    padding: 28px 30px;
    button {
      height: 40px;
      width: fit-content;
      margin-left: auto;
    }
  }
`
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;

  h2 {
    margin-right: 1rem;
    margin-bottom: 0 !important;
  }
  button {
    white-space: nowrap;
    height: 40px;
    padding-left: 1rem;
    padding-right: 1rem;
    color: ${p => p.theme.color.accent};
    background: white;
  }
`

const Sm = styled.p`
  font-size: 13px;
  margin-top: -12px;
  margin-bottom: 1rem;
  color: ${p => p.theme.color.grey};
`

const fields = [
  { name: 'name', type: 'text', label: 'Name' },
  { name: 'reference_numbers', type: 'text', label: 'Employee id' },
  { name: 'NI_no', type: 'text', label: 'National Insurance No.' },
]

const Details = ({
  details,
  sendFeedback,
  changePassword,
  updateEmail,
  user,
  isHolding,
}) => {
  const [email, setEmail] = useState(details.email)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordErrors, setPasswordErrors] = useState([])
  const [feedback, setFeedback] = useState()
  const { addToast } = useToasts()

  const handleUpdateEmail = async () => {
    if (!email) return
    const res = await updateEmail(email)
    if (res.error) {
      const message = (res.message || {}).email || ''
      addToast(`Email could not be updated. ${message}`, errorToast)
    } else {
      addToast('Email has been updated')
    }
  }
  const handleFeedback = async () => {
    if (!feedback) return
    const res = await sendFeedback(feedback)
    if (res.error) {
      addToast(
        `There was a problem submitting your feedback. ${res.message}`,
        errorToast,
      )
    } else {
      addToast('Your feedback has been submitted. Thank you')
      setFeedback('')
    }
  }

  const handleChangePassword = async () => {
    let errors = []
    if (!password) {
      errors = ['Password must not be empty']
    } else if (!confirmPassword) {
      errors = [...errors, 'Confirm password must not be empty']
    } else if (password.length < 5) {
      errors = [...errors, 'Password must be longer than 5 charactors']
    } else if (password !== confirmPassword) {
      errors = [...errors, 'Confirm password must match with password']
    }
    setPasswordErrors(errors)
    if (errors.length >= 1) {
      return
    }
    const res = await changePassword(password)
    if (res.error) {
      addToast(
        `There was a problem changing the password. ${res.message}`,
        errorToast,
      )
    } else {
      addToast('The password has been changed')
      setPassword('')
      setConfirmPassword('')
    }
  }

  const isSuperAdmin = user.super_admin
  const isAdmin = user.is_admin
  const canUpdateEmail =
    isSuperAdmin || (isAdmin && !details.is_admin && details.email)

  return (
    <>
      <Flex>
        <SubTitle>Worker Details</SubTitle>
      </Flex>

      <DetailsPanel>
        {fields.map(({ name, type, label }) => (
          <Input
            key={name}
            name={name}
            type={type}
            label={label}
            value={details[name]}
            disabled
          />
        ))}
        <Input
          name="email"
          type="email"
          label="Email"
          value={email}
          disabled={!canUpdateEmail || isHolding}
          onChange={e => setEmail(e.target.value)}
        />
        {canUpdateEmail && !isHolding ? (
          <>
            <Sm>Email updates will be logged for auditing purposes.</Sm>
            <Button onClick={handleUpdateEmail}>Update Email</Button>
          </>
        ) : null}
        {!isAdmin && (
          <>
            <Textarea
              name="feedback"
              label="Feedback"
              value={feedback}
              onChange={e => setFeedback(e.target.value)}
              rows={10}
            ></Textarea>
            <Button onClick={handleFeedback}>Send feedback</Button>
          </>
        )}
        {isAdmin && !isHolding ? (
          <>
            <Input
              name="password"
              type="password"
              label="password"
              value={password}
              autocomplete="off"
              onChange={e => setPassword(e.target.value)}
            />
            <Input
              name="confirm_password"
              type="password"
              label="Confirm password"
              value={confirmPassword}
              autocomplete="off"
              disabled={password.length === 0}
              onChange={e => setConfirmPassword(e.target.value)}
            />
            {passwordErrors
              ? passwordErrors.map(e => (
                  <p key={e} style={{ fontSize: '14px', color: '#db3e57' }}>
                    {e}
                  </p>
                ))
              : null}
            <Button onClick={handleChangePassword}>Change password</Button>
          </>
        ) : null}
      </DetailsPanel>
    </>
  )
}

Details.defaultProps = {
  isHolding: false,
}

Details.propTypes = {
  details: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    NI_no: PropTypes.string,
    email: PropTypes.string,
    is_admin: PropTypes.oneOf([0, 1]),
  }).isRequired,
  user: PropTypes.shape({
    is_admin: PropTypes.oneOf([0, 1]),
    super_admin: PropTypes.oneOf([0, 1]),
  }).isRequired,
  sendFeedback: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  updateEmail: PropTypes.func.isRequired,
  isHolding: PropTypes.bool,
}
export default Details
