import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { useAuth } from '../../auth'
import { Button, Input } from '../../ui'
import { ReactComponent as Logo } from '../../assets/pinpoint_logo.svg'
import { Container, Box, Text, StyledLink, ErrMsg } from './ui'

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be an email')
    .required('Required'),
})

const ForgotPassword = () => {
  const { resetPassword } = useAuth()

  const submit = async ({ email }, actions) => {
    await resetPassword(email)
    actions.setSubmitting(false)
  }

  return (
    <Container>
      <Box>
        <Logo height="54" />
        <Text>Reset Your Password</Text>

        <Formik
          initialValues={{
            email: '',
          }}
          validationSchema={forgotPasswordSchema}
          onSubmit={submit}
        >
          {({ isSubmitting }) => (
            <>
              <Form>
                <Field
                  name="email"
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="email"
                      name="email"
                      label="Email Address"
                      placeholder="Enter your email address"
                    />
                  )}
                />
                <ErrorMessage component={ErrMsg} name="email" />

                <Button fullwidth tall type="submit" disabled={isSubmitting}>
                  Send Password Reset
                </Button>
              </Form>
            </>
          )}
        </Formik>
        <StyledLink to="/">Cancel</StyledLink>
      </Box>
    </Container>
  )
}

export default ForgotPassword
