import styled, { css } from 'styled-components'

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${p => (p.tall ? '54px' : '40px')};
  color: white;
  font-size: 18px;
  line-height: 18px;
  border-radius: 27px;
  width: ${p => (p.fullwidth ? '100%' : 'fit-content')};
  border: none;
  background: ${p => p.theme.style.primary_gradient};
  cursor: pointer;
  text-decoration: none;
  padding: 11px 2rem;
  ${p =>
    p.center
      ? css`
          margin-left: auto;
          margin-right: auto;
        `
      : null}
  &:hover,
  &:focus {
    background: ${p => p.theme.style.primary_gradient__active};
  }
  &:disabled {
    background: lightgrey;
  }
`

export { Button }
