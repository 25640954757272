import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { PDFDownloadLink, pdf } from '@react-pdf/renderer'
import styled, { css } from 'styled-components'
import { useToasts } from 'react-toast-notifications'

import PayslipPDF from './payslipPDF'
import API from '../../../api'

const style = css`
  display: inline-block;
  background: ${p => p.theme.style.primary_gradient};
  border-radius: 27px;
  font-size: 15px;
  padding: 11px 8px;
  color: white;
  height: 40px;
  max-width: 140px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  &:hover,
  &:focus {
    background: ${p => p.theme.style.primary_gradient__active};
  }
`
const GeneratePDFButton = styled.div`
  ${style}
`
const StyledDownloadLink = styled(PDFDownloadLink)`
  ${style}
  text-decoration: none;
  margin: 2.5px 0;
  background: ${p =>
    p.isReady ? p.theme.color.accent : p.theme.style.primary_gradient};
  &:hover,
  &:focus {
    background: ${p =>
      p.isReady ? '#c2243d' : p.theme.style.primary_gradient__active};
  }
`

const EmailPDFButton = styled.button`
  ${style}
  border: none;
  margin: 2.5px 0 2.5px 5px;
  &:disabled {
    cursor: not-allowed;
    filter: brightness(70%);
  }
`

// Fetch data for payslip pdf
const PayslipContainer = ({ id }) => {
  const [isFetching, setIsFetching] = useState(false)
  const [emailSent, setEmailSent] = useState(false)
  const [payslipData, setPayslipData] = useState(null)
  const [isReady, setIsReady] = useState(false)
  const { addToast } = useToasts()

  async function generatePDF() {
    if (!isFetching && !isReady) {
      setIsFetching(true)
      try {
        const res = await API.fetchPayslipData(id)
        if (res.error) throw Error('Unable to fetch payslip')
        setPayslipData(res)
      } catch (e) {
        addToast(e.message || 'Error', { appearance: 'error' })
      }
    }
  }

  async function sendPDF() {
    const file = pdf(<PayslipPDF {...payslipData} />).toBlob()
    const res = await API.emailPDF(id, await file)
    if (res.error) throw Error('Unable to send payslip')
    else setEmailSent(true)
  }

  return payslipData ? (
    <>
      <StyledDownloadLink
        document={<PayslipPDF {...payslipData} />}
        fileName={`${id}.pdf`}
        isReady={isReady}
      >
        {({ loading }) => {
          setIsReady(!loading)
          return loading ? 'Generating...' : 'Download'
        }}
      </StyledDownloadLink>
      {isReady ? (
        <EmailPDFButton
          type="button"
          onClick={() => sendPDF()}
          disabled={emailSent}
        >
          {emailSent ? 'Sent' : 'Email'}
        </EmailPDFButton>
      ) : null}
    </>
  ) : (
    <GeneratePDFButton
      onClick={generatePDF}
      className="generate-payslip"
      disabled={isFetching}
    >
      {isFetching ? 'Fetching...' : 'Generate'}
    </GeneratePDFButton>
  )
}

PayslipContainer.propTypes = {
  id: PropTypes.number.isRequired,
}
export default PayslipContainer
