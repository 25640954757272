import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Select from 'react-select'

import { getShortMonth } from '../../utilities'
import { SubTitle, Table, ViewIcon, ViewLink, ViewBtn } from '../../ui'
import MonthlyPayslipsList from './monthlyPayslipsList'
import P60Container from '../p60'

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  min-height: 48px;
  margin-bottom: 1rem;
`

const InlineSubTitle = styled(SubTitle)`
  margin-bottom: 0 !important;
  margin-right: 1rem;
`

const SelectContainer = styled(Flex)`
  flex: 1;
  align-items: center;
  margin-bottom: 0;
  min-width: 150px;

  .select-year__control {
    border-radius: 1rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .select-year__indicator-separator {
    display: none;
  }
  .select-year__dropdown-indicator {
    color: ${p => p.theme.color.accent};
  }
`
const Label = styled.label`
  margin-left: auto;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  color: ${p => p.theme.color.text};
  letter-spacing: 1px;
`
const StyledSelect = styled(Select)`
  flex: 1;
  margin-left: 0.5rem;
  max-width: 150px;
`

const CloseBtn = styled.button`
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  background: ${p => p.theme.style.mono_gradient};
  border-radius: 54px;
  border: none;
  color: white;
  width: 100%;
  max-width: 80px;
  padding: 0 40px;
  ${p => p.theme.layout.tablet} {
    width: 105px;
    max-width: 105px;
  }
`

const Payslips = ({
  payslipsByYear,
  referenceNumbers,
  refNum,
  setRefNum,
  p60,
  showPayslips,
}) => {
  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }

  const sortDescending = (a, b) => +b - +a
  const sortedYears = Object.keys(payslipsByYear)
    .concat(p60.map(p60Instance => p60Instance.year.toString()))
    .filter(onlyUnique)
    .sort(sortDescending)
    .map(value => ({ value, label: value }))

  console.log(sortedYears);

  const [year, setYear] = useState(sortedYears[0].value)
  const [month, setMonth] = useState()
  const [currentP60s, setCurrentP60s] = useState([])

  React.useEffect(() => {
    const filtered = p60.filter(p => parseInt(p.year) === parseInt(year))
    setCurrentP60s(filtered)
  }, [year, p60])

  if (!month) {
    return (
      <>
        <Flex>
          <InlineSubTitle>Payslips: Tax Year {year}</InlineSubTitle>
          <SelectContainer>
            <Label htmlFor="select-year">Year:</Label>

            <StyledSelect
              classNamePrefix="select-year"
              inputId="select-year"
              onChange={option => setYear(option.value)}
              options={sortedYears}
              defaultValue={{ value: year, label: year }}
            ></StyledSelect>
          </SelectContainer>
        </Flex>
        <SelectContainer>
          {currentP60s?.length >= 1 ? (
            currentP60s.map(p => (
              <Table key={p.year}>
                <thead>
                  <tr>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>P60</td>
                    <td style={{ textAlign: 'right' }}>
                      {showPayslips ? <P60Container p60={p} /> : null}
                    </td>
                  </tr>
                </tbody>
              </Table>
            ))
          ) : (
            <p style={{ width: '100%' }}>{'No P60 available for ' + year}</p>
          )}
          <Label htmlFor="select-year">Reference number:</Label>

          <StyledSelect
            classNamePrefix="select-reference"
            inputId="select-reference"
            onChange={refOpt => setRefNum(refOpt.value)}
            options={referenceNumbers}
            defaultValue={{ value: refNum, label: refNum }}
          ></StyledSelect>
        </SelectContainer>
        <Table>
          <thead>
            <tr>
              <th>Month</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {payslipsByYear[year] ? (
              Object.keys(payslipsByYear[year]).map(m => (
                <tr key={m}>
                  <td>{getShortMonth(year, m)}</td>
                  <td>
                    <ViewLink as="button" onClick={() => setMonth(m)}>
                      <ViewIcon data-mobile />
                      <ViewBtn data-tablet data-web className="view-month">
                        View All
                      </ViewBtn>
                    </ViewLink>
                  </td>
                </tr>
              ))
            ) : (
              <p style={{ paddingLeft: '1rem', width: '100%' }}>
                No payslips to show with {refNum} for {year}.
              </p>
            )}
          </tbody>
        </Table>
      </>
    )
  } else {
    return (
      <>
        <Flex>
          <InlineSubTitle>
            Payslips: {getShortMonth(year, month)} {year}
          </InlineSubTitle>
          <CloseBtn className="close" onClick={() => setMonth()}>
            Close
          </CloseBtn>
        </Flex>
        <MonthlyPayslipsList
          payslips={payslipsByYear[year][month]}
          month={month}
          showPayslips={showPayslips}
        />
      </>
    )
  }
}

Payslips.propTypes = {
  payslipsByYear: PropTypes.object.isRequired,
  referenceNumbers: PropTypes.array.isRequired,
  refNum: PropTypes.string.isRequired,
  setRefNum: PropTypes.func.isRequired,
  p60: PropTypes.array.isRequired,
  showPayslips: PropTypes.bool.isRequired,
}
export default Payslips
