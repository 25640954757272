import React, { useState, useContext, useCallback } from 'react'
import { useToasts } from 'react-toast-notifications'
import { navigate } from '@reach/router'

import API, { api } from '../api'

const AuthContext = React.createContext()
const errorToast = { appearance: 'error' }

// Only login related endpoints available without auth
// accessToken is required for all other endpoints
export const AuthProvider = props => {
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [user, setUser] = useState()
  const { addToast } = useToasts()

  const login = async credentials => {
    const res = await API.login(credentials)
    setLoginState(res.user)
    if (!res.user) {
      addToast(
        'Unable to login. Please check your email address and password',
        errorToast,
      )
    }
  }

  const reAuth = useCallback(async () => {
    const access_token = localStorage.getItem('auth')
    if (access_token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
      const res = await API.fetch({ url: '/get-user' })
      setLoginState(res.success)
      if (!res.success) {
        localStorage.removeItem('auth')
        addToast('Please login again.', errorToast)
      }
    } else {
      setIsAuthenticating(false)
    }
  }, [addToast])

  const logout = async () => {
    await API.logout()
    setLoginState(null)
    addToast('You have been logged out')
  }

  const setLoginState = user => {
    setUser(user)
    setIsAuthenticated(!!user)
    setIsAuthenticating(false)
  }

  const updatePassword = async ({ password, hash }) => {
    // send new password and hash to server
    // updates password if hash is correct
    const res = await API.updatePassword({
      new_password: password,
      hash,
    })
    if (res.error) {
      const errorMsg = typeof res.message === 'string' ? `: ${res.message}` : ''
      return addToast(`Unable to update password${errorMsg}`, errorToast)
    } else {
      addToast('Your password has been updated')
      navigate('/')
    }
  }

  const resetPassword = async email => {
    // send email address
    // if email address exists, sends email with link
    const res = await API.resetPassword(email)
    if (res.error) {
      addToast(
        'Unable to send reset email. Please check your email address',
        errorToast,
      )
      return
    } else {
      addToast('Reset email has been sent. Please check your emails')
      navigate('/')
    }
  }

  const resetLinkCheck = async link => {
    const res = await API.linkCheck(link)
    if (res.error) {
      addToast('This reset link is no longer valid', errorToast)
      return { res }
    }
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticating,
        isAuthenticated,
        login,
        logout,
        reAuth,
        user,
        updatePassword,
        resetPassword,
        resetLinkCheck,
      }}
      {...props}
    />
  )
}

export const useAuth = () => useContext(AuthContext)
