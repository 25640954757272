import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { useFetch } from '../../utilities'
import { useAuth } from '../../auth'
import API from '../../api'

import Header from './header'
import Details from './details'
import Payslips from './payslips'
import Loader from '../loader'

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  ${p => p.theme.layout.tablet} {
    flex-direction: row;
  }
`
export const Section = styled.section`
  flex: 1;
  margin-bottom: 40px;
  h2 {
    margin-bottom: 25px;
  }
  ${p => p.theme.layout.tablet} {
    margin-left: 0;
    margin-right: 1rem;
  }
`

const UserContainer = ({ id, isHolding }) => {
  const { user } = useAuth()
  const isOwnPayslip = user.id === parseInt(id)
  const showPayslips = !isHolding && (user.is_admin || isOwnPayslip)
  const showAllPayslips = !isHolding && (user.super_admin || isOwnPayslip)

  const getURL = () => {
    if (isHolding) return `/admin/get-holding-user/${id}`
    if (user.is_admin) return `/admin/get-user/${id}`
    return '/get-user'
  }

  const {
    data: details,
    error: detailsError,
    isFetching: isFetchingDetails,
  } = useFetch(getURL())

  const handleFeedback = feedback => API.sendFeedback(feedback)
  const handleChangePassword = password => API.changePassword(password, id)
  const handleUpdateEmail = email => API.changeEmail(email, id)

  return (
    <Loader isFetching={isFetchingDetails} error={detailsError}>
      {details && (
        <>
          <Header user={details.success} />
          <Main>
            <Section>
              <Details
                user={user}
                isHolding={isHolding}
                details={details.success}
                sendFeedback={handleFeedback}
                changePassword={handleChangePassword}
                updateEmail={handleUpdateEmail}
              />
            </Section>
            {showPayslips ? (
              <Section>
                <Payslips
                  id={id}
                  p60={details.success.P60s}
                  referenceNumbers={
                    details.success.reference_numbers
                      ? details.success.reference_numbers
                      : []
                  }
                  showPayslips={showAllPayslips}
                />
              </Section>
            ) : null}
          </Main>
        </>
      )}
    </Loader>
  )
}

UserContainer.defaultProps = {
  id: '',
  isHolding: false,
}

UserContainer.propTypes = {
  id: PropTypes.string,
  isHolding: PropTypes.bool,
}

export default UserContainer
