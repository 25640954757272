import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import styled from 'styled-components'
import * as Yup from 'yup'

import { useAuth } from '../../auth'
import { ReactComponent as Logo } from '../../assets/pinpoint_logo.svg'
import { Button, Input } from '../../ui'
import { Container, Box, Text, ErrMsg, StyledLink } from './ui'
import Terms from './terms'

const TermsBtn = styled.button`
  border: none;
  background: none;
  display: block;
  cursor: pointer;
  font-weight: normal;
  color: ${p => p.theme.color.primary};
  text-decoration: underline;
  margin-bottom: 2rem;
`
const Checkbox = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  input,
  label {
    cursor: pointer;
  }
  input {
    margin-right: 0.5rem;
  }
`

const updatePasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(7, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  confirm_password: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
  accept_terms: Yup.bool().oneOf([true], 'Required'),
})

const UpdatePassword = ({ hash }) => {
  const { updatePassword, resetLinkCheck } = useAuth()
  const [showTerms, setShowTerms] = useState(false)
  const [showReset, setShowReset] = useState(false)

  const submitPassword = async ({ password }, actions) => {
    await updatePassword({ password, hash })
    actions.setSubmitting(false)
  }

  React.useEffect(() => {
    const checkResetLink = async () => {
      const res = await resetLinkCheck(hash)
      if (res) {
        setShowReset(true)
      }
    }
    if (hash) checkResetLink()
  }, [hash, resetLinkCheck])

  return (
    <Container>
      <Box>
        <Logo height="54" />
        {showReset ? (
          <>
            <Text>Invalid reset link</Text>
            <p>
              Sorry there was a problem with your link. This could be because
              the link you have used isn&apos;t the most recent link we&apos;ve
              sent you, please check your emails or try again.
            </p>
            <StyledLink to="/forgot-password">Forgot Password?</StyledLink>
          </>
        ) : (
          <>
            <Text>Reset Your Password</Text>
            <Formik
              initialValues={{
                password: '',
                confirm_password: '',
                accept_terms: false,
              }}
              validationSchema={updatePasswordSchema}
              onSubmit={submitPassword}
            >
              {({ isSubmitting }) => (
                <>
                  <Form>
                    <Field
                      name="password"
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="password"
                          name="password"
                          label="New Password"
                          placeholder="Enter new password"
                        ></Input>
                      )}
                    />
                    <ErrorMessage component={ErrMsg} name="password" />

                    <Field
                      name="confirm_password"
                      render={({ field }) => (
                        <Input
                          {...field}
                          type="password"
                          name="confirm_password"
                          label="Re-enter New Password"
                          placeholder="Confirm new password"
                        />
                      )}
                    />
                    <ErrorMessage component={ErrMsg} name="confirm_password" />

                    <Field
                      name="accept_terms"
                      render={({ field }) => (
                        <Checkbox>
                          <input
                            id="accept_terms"
                            name="accept_terms"
                            type="checkbox"
                            {...field}
                          />
                          <label htmlFor="accept_terms"> Accept Terms</label>
                        </Checkbox>
                      )}
                    />
                    <ErrorMessage component={ErrMsg} name="accept_terms" />

                    <TermsBtn type="button" onClick={() => setShowTerms(true)}>
                      View terms
                    </TermsBtn>

                    <Button
                      fullwidth
                      tall
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Confirm
                    </Button>
                  </Form>
                </>
              )}
            </Formik>
          </>
        )}
      </Box>
      {showTerms && <Terms close={() => setShowTerms(false)} />}
    </Container>
  )
}

UpdatePassword.defaultProps = {
  hash: '',
}
UpdatePassword.propTypes = {
  hash: PropTypes.string,
}

export default UpdatePassword
