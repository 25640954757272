import { useState, useEffect, useCallback } from 'react'
import queryString from 'query-string'

import API from '../api'

const useFetch = (URL, queries, refNum) => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(false)
  const [isFetching, setIsFetching] = useState(true)

  useEffect(() => {
    if (!URL) return undefined

    const fetchData = async (url, paramString, refNum) => {
      setIsFetching(true)
      const params = queryString.parse(paramString)
      try {
        const res = await API.fetch({
          url,
          params,
          headers: { 'X-ReferenceNumber': refNum },
        })
        if (res.error) {
          return setError(res.message)
        }
        setData(res)
      } catch (e) {
        setError('Error')
      } finally {
        setIsFetching(false)
      }
    }

    fetchData(URL, queries, refNum)
  }, [URL, queries, refNum])

  return {
    data,
    error,
    isFetching,
  }
}

const usePaginatedFetch = URL => {
  // params
  const [url, setUrl] = useState('')
  const [page, setPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState('')
  // response
  const [hasMore, setHasMore] = useState(false)
  const [total, setTotal] = useState(0)
  const [error, setError] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState(null)

  useEffect(() => {
    setUrl(URL)
  }, [URL, setUrl])

  // first mount
  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true)

      try {
        const res = await API.fetch({
          url: url + (searchQuery ? `/${searchQuery}` : ''),
          params: { page },
        })
        if (res.error) {
          return setError(res.message)
        }
        // if fetching more
        if (page > 1) {
          // append results
          setData(existingData => [...existingData, ...res.data])
        } else {
          setData(res.data)
        }
        setTotal(res.total)
        setHasMore(!!res.next_page_url)
      } catch (e) {
        setError('Error')
      } finally {
        setIsFetching(false)
      }
    }
    url && fetchData()
  }, [url, page, searchQuery])

  const fetchMore = useCallback(() => {
    setPage(page => (page += 1))
  }, [])

  const search = async query => {
    setSearchQuery(query)
  }

  return {
    data,
    error,
    isFetching,
    hasMore,
    total,
    search,
    fetchMore,
  }
}

export { useFetch, usePaginatedFetch }
