import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'

import { useAuth } from '../../auth'
import { Button, Input } from '../../ui'
import { ReactComponent as Logo } from '../../assets/pinpoint_logo.svg'
import { Container, Box, Text, StyledLink, ErrMsg } from './ui'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Must be an email')
    .required('Required'),
  password: Yup.string()
    .min(7, 'Too short')
    .required('Required'),
})

export default function Login() {
  const { login } = useAuth()

  const submitLogin = async ({ email, password }, actions) => {
    await login({ email, password })
    actions.setSubmitting(false)
  }

  return (
    <Container>
      <Box>
        <Logo height="54" />
        <Text>Log in to your account</Text>
        <Formik
          validationSchema={loginSchema}
          onSubmit={submitLogin}
          initialValues={{
            email: '',
            password: '',
          }}
        >
          {({ isSubmitting }) => (
            <>
              <Form>
                <Field
                  name="email"
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="email"
                      name="email"
                      label="Email Address"
                      placeholder="Enter email"
                    />
                  )}
                />
                <ErrorMessage component={ErrMsg} name="email" />

                <Field
                  name="password"
                  render={({ field }) => (
                    <Input
                      {...field}
                      type="password"
                      name="password"
                      label="Password"
                      placeholder="Enter password"
                    />
                  )}
                />
                <ErrorMessage component={ErrMsg} name="password" />

                <Button fullwidth tall type="submit" disabled={isSubmitting}>
                  Log In
                </Button>
              </Form>
            </>
          )}
        </Formik>

        <StyledLink to="/forgot-password">Forgot Password?</StyledLink>
      </Box>
    </Container>
  )
}
