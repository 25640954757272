import axios from 'axios'

export const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production'
      ? process.env.REACT_APP_API
      : process.env.REACT_APP_DEV_API,
  // https://payslip-api.pinpoint-portal.co.uk/api/v1 ---- prod
  // https://pinpointapi.ne6.dev/api/v1 --- dev
})

const errorResponse = (e, message = '') => ({
  error: true,
  message: ((e.response || {}).data || {}).error || message,
})

const login = async ({ email, password }) => {
  try {
    const res = await api.post('/login', { email, password })
    if (!res.data.length) return { error: true, message: 'Error logging in' }
    const access_token = res.data[1].access_token
    // eslint-disable-next-line
    api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`

    localStorage.setItem('auth', access_token)
    return { user: res.data[0].user }
  } catch (e) {
    return errorResponse(e)
  }
}

const logout = async () => {
  try {
    await api.get('/logout')
    // eslint-disable-next-line
    api.defaults.headers.common['Authorization'] = null
    localStorage.removeItem('auth')
    return { error: false }
  } catch (e) {
    return errorResponse(e)
  }
}

const updatePassword = async data => {
  try {
    await api.post('/update-password', data)
    return { error: false }
  } catch (e) {
    return errorResponse(e)
  }
}

const resetPassword = async email => {
  try {
    await api.post('/password-reset', { email })
    return { error: false }
  } catch (e) {
    return errorResponse(e)
  }
}

const changeEmail = async (email, id) => {
  try {
    await api.post(`/change-email/${id ? id : ''}`, { email })
    return { error: false }
  } catch (e) {
    return errorResponse(e)
  }
}

const sendFeedback = async feedback => {
  try {
    await api.post('/feedback', { feedback })
    return { error: false }
  } catch (e) {
    return errorResponse(e)
  }
}

const fetch = async ({ url, params, headers }) => {
  try {
    const result = await api.get(url, {
      params,
      headers,
    })
    if (!result.data.error) return result.data
    return { error: true, message: result.data.message }
  } catch (e) {
    return errorResponse(e)
  }
}

const fetchPayslipData = async id => {
  try {
    const result = await api.get(`/payslips/${id}`)
    if (!result.data.error) return result.data
    return { error: true, message: result.data.message }
  } catch (e) {
    return errorResponse(e)
  }
}

const uploadFile = async (file, type) => {
  const formData = new FormData()
  formData.append('file', file)
  console.log(type)
  const url = type === 'p60' ? '/p60-import' : '/import'

  try {
    const result = await api.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    if (!result.data.error)
      return { error: false, message: result.data.success }
    return { error: true, message: result.data.message }
  } catch (e) {
    return errorResponse(e)
  }
}

const changePassword = async (password, id) => {
  try {
    await api.post(`/admin-reset-password/${id ? id : ''}`, { password })
    return { error: false }
  } catch (e) {
    return errorResponse(e)
  }
}

const linkCheck = async link => {
  try {
    const result = await api.get(`/check-reset/${link}`)
    if (!result.data.error) return result.data
    return { error: true, message: result.data.message }
  } catch (e) {
    return errorResponse(e)
  }
}

const emailPDF = async (id, pdf) => {
  const formData = new FormData()
  formData.append('payslip', pdf)

  try {
    const result = await api.post(`/payslips/${id}/email`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    if (!result.data.error)
      return { error: false, message: result.data.success }
    return { error: true, message: result.data.message }
  } catch (e) {
    return errorResponse(e)
  }
}

export default {
  login,
  logout,
  updatePassword,
  resetPassword,
  changeEmail,
  sendFeedback,
  fetch,
  fetchPayslipData,
  uploadFile,
  changePassword,
  linkCheck,
  emailPDF,
}
