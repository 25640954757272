import { format } from 'date-fns'

const getShortMonth = (year, month) => {
  try {
    return format(new Date(year, month - 1), 'MMM')
  } catch (e) {
    return 'Invalid date'
  }
}

export { getShortMonth }
