import React from 'react'
import PropTypes from 'prop-types'
import {
  Font,
  StyleSheet,
  Document,
  Page,
  View,
  Text,
  Image,
} from '@react-pdf/renderer'

import logo from '../../../assets/pinpoint2.jpg'

import ProximaNovaAlt from '../../../assets/fonts/ProximaNovaAlt.ttf'
import ProximaNovaAltSemi from '../../../assets/fonts/ProximaNovaAlt-Semi.ttf'
import ProximaNovaAltBold from '../../../assets/fonts/ProximaNovaAlt-Bold.ttf'

Font.register({
  family: 'ProximaNovaAlt',
  fonts: [
    { src: ProximaNovaAlt },
    { src: ProximaNovaAltSemi, fontWeight: 600 },
    { src: ProximaNovaAltBold, fontWeight: 700 },
  ],
})

const styles = StyleSheet.create({
  semi: {
    fontWeight: 600,
  },
  bold: {
    fontWeight: 700,
  },
  body: {
    fontFamily: 'ProximaNovaAlt',
    fontSize: 13,
  },
  header: {
    general: {
      padding: '20px 24px',
    },
    logo: {
      width: '106px',
      marginBottom: '20px',
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  main: {
    general1: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      backgroundColor: '#F2F6FE',
      padding: '20px 24px',
    },
    general2: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: '20px 24px',
    },
    section: {
      flexBasis: '32%',
      fontSize: 10,
    },
    head: {
      fontWeight: 700,
    },
    box: {
      marginTop: 5,
      backgroundColor: '#ffffff',
      fontSize: 8,
      borderWidth: 1,
      borderColor: '#DEDEDE',
      padding: 10,
      flex: 1,
      minHeight: 140,
    },
    row: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 4,
    },
    row__tall: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 4,
      marginBottom: 8,
    },
    center: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 30,
    },
  },
  footer: {
    general: {
      fontSize: 8,
      padding: '0 24px',
    },
  },
})

// Payslips view
const PayslipPDF = ({ user, summary, payments, deductions }) => {
  const toMoney = value => {
    try {
      const money = value.toLocaleString('en-UK', {
        style: 'currency',
        currency: 'GBP',
      })
      return money
    } catch (e) {
      return 'Invalid amount'
    }
  }
  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.header.general}>
          <Image src={logo} style={styles.header.logo} />
          <View style={styles.header.row}>
            <Text>
              <Text style={styles.semi}>Name: </Text>
              <Text>{user.name}</Text>
            </Text>
            <Text>
              <Text style={styles.semi}>Date: </Text>
              <Text>{summary.date}</Text>
            </Text>
          </View>
        </View>

        <View style={styles.main.general1}>
          <View style={styles.main.section}>
            <Text>Employee Details</Text>
            <View style={styles.main.box}>
              <View style={styles.main.row}>
                <Text style={styles.semi}>Payroll</Text>
                <Text>{summary.payroll}</Text>
              </View>
              <View style={styles.main.row}>
                <Text style={styles.semi}>Ref No: </Text>
                <Text>{summary.ref_no}</Text>
              </View>
              <View style={styles.main.row}>
                <Text style={styles.semi}>Date: </Text>
                <Text>{summary.date}</Text>
              </View>
              <View style={styles.main.row}>
                <Text style={styles.semi}>Tax Week/Month: </Text>
                <Text>{summary.tax_month}</Text>
              </View>
              <View style={styles.main.row}>
                <Text style={styles.semi}>Tax Code: </Text>
                <Text>{summary.tax_code}</Text>
              </View>
              <View style={styles.main.row}>
                <Text style={styles.semi}>NI No: </Text>
                <Text>{summary.NI_no}</Text>
              </View>
              <View style={styles.main.row}>
                <Text style={styles.semi}>W1/M1 Basis: </Text>
                <Text>{parseInt(summary.tax_basis) ? 'Y' : 'N'}</Text>
              </View>
            </View>
          </View>

          <View style={styles.main.section}>
            <Text>Payments</Text>
            <View style={styles.main.box}>
              {payments.map(payment => (
                <React.Fragment key={payment.id}>
                  <Text style={styles.bold}>{payment.payment_name}</Text>
                  <View style={styles.main.row__tall}>
                    {payment.hours ? (
                      <Text style={styles.semi}>
                        {parseFloat(payment.hours).toFixed(2)} (Hrs)
                      </Text>
                    ) : null}
                    {payment.rate ? (
                      <Text style={styles.semi}>
                        {toMoney(payment.rate)} (Rate)
                      </Text>
                    ) : null}
                    <Text>{toMoney(payment.value)}</Text>
                  </View>
                </React.Fragment>
              ))}
            </View>
          </View>

          <View style={styles.main.section}>
            <Text>Deductions</Text>
            <View style={styles.main.box}>
              {deductions.map(deduction => (
                <View key={deduction.id} style={styles.main.row}>
                  <Text style={styles.semi}>{deduction.deduction_name}</Text>
                  <Text>{toMoney(deduction.value)}</Text>
                </View>
              ))}
            </View>
          </View>
        </View>

        <View style={styles.main.general2}>
          <View style={styles.main.section}>
            <Text>Year to Date</Text>
            <View style={styles.main.box}>
              <View style={styles.main.row}>
                <Text style={styles.semi}>Pay To Date:</Text>
                <Text>{toMoney(summary.gross_pay_ytd)}</Text>
              </View>
              <View style={styles.main.row}>
                <Text style={styles.semi}>Taxable:</Text>
                <Text>{toMoney(summary.taxable_pay_ytd)}</Text>
              </View>
              <View style={styles.main.row}>
                <Text style={styles.semi}>Non Taxable:</Text>
                <Text>{toMoney(summary.non_taxable_pay_ytd)}</Text>
              </View>
              <Text style={{ ...styles.bold, marginTop: 8, marginBottom: 4 }}>
                Deductions
              </Text>
              {deductions.map(deduction => (
                <React.Fragment key={deduction.id}>
                  <View style={styles.main.row}>
                    <Text style={styles.semi}>{deduction.deduction_name}:</Text>
                    <Text>{toMoney(deduction.balance)}</Text>
                  </View>
                </React.Fragment>
              ))}
            </View>
          </View>

          <View style={styles.main.section}>
            <Text>Summary</Text>
            <View style={styles.main.box}>
              <View style={styles.main.row}>
                <Text style={styles.semi}>Taxable Pay:</Text>
                <Text>{toMoney(summary.taxable_pay)}</Text>
              </View>
              <View style={styles.main.row}>
                <Text style={styles.semi}>Non Taxable Pay:</Text>
                <Text>{toMoney(summary.non_taxable_pay)}</Text>
              </View>
              <View style={styles.main.row}>
                <Text style={styles.semi}>Total Pay:</Text>
                <Text>{toMoney(summary.net_payment)}</Text>
              </View>
              <View style={styles.main.row}>
                <Text style={styles.semi}>Total Deductions:</Text>
                <Text>{toMoney(summary.total_deductions)}</Text>
              </View>
            </View>
          </View>

          <View style={styles.main.section}>
            <Text>Net Pay</Text>
            <View style={styles.main.box}>
              <View style={styles.main.row}>
                <Text style={styles.semi}>Pay Method:</Text>
                <Text>BANK</Text>
              </View>
              <View style={styles.main.center}>
                <Text>{toMoney(summary.net_payment)}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

PayslipPDF.propTypes = {
  user: PropTypes.object.isRequired,
  summary: PropTypes.object.isRequired,
  payments: PropTypes.array.isRequired,
  deductions: PropTypes.array.isRequired,
}

export default PayslipPDF
