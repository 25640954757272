import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Title, Input, Button } from '../../ui'
import { ReactComponent as Search } from '../../assets/icons/search.svg'

const Header = styled.header`
  display: block;
  margin-bottom: 25px;

  h1 {
    margin-bottom: 25px;
  }
  ${p => p.theme.layout.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h1 {
      margin-bottom: 0;
    }
  }
`

const Form = styled.form`
  display: flex;

  input {
    height: 40px;
    margin: 0;
    margin-right: 1rem;
    font-size: 16px;
  }
  button {
    height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 15px;
    margin-left: 0.5rem;
  }
`

const SearchForm = ({ title, onSearch }) => {
  const [value, setValue] = useState('')

  const handleChange = e => {
    const { value: v } = e.target
    setValue(v.trim())
  }

  const handleSearch = e => {
    e.preventDefault()
    // if (!value) return
    onSearch(value)
  }

  return (
    <Header>
      <Title>{title}</Title>
      <Form onSubmit={handleSearch}>
        <Input
          type="text"
          placeholder="Search"
          name="search"
          value={value}
          onChange={handleChange}
          icon={Search}
        />
        <Button type="submit">Go</Button>
      </Form>
    </Header>
  )
}

SearchForm.propTypes = {
  title: PropTypes.string.isRequired,
  onSearch: PropTypes.func.isRequired,
}
export default SearchForm
