import React from 'react'

import { usePaginatedFetch } from '../../utilities'
import WorkersTable from './workers'
import SearchForm from '../search-form'
import Loader from '../loader'
import { Button } from '../../ui'

const WorkersContainer = () => {
  const {
    isFetching,
    error,
    data,
    search,
    hasMore,
    fetchMore,
  } = usePaginatedFetch('/admin/workers')

  // search term used to fetch data from api, results update data
  const handleSearch = query => {
    search(query)
  }

  return (
    <Loader isFetching={isFetching} error={error}>
      {data && (
        <>
          <SearchForm onSearch={handleSearch} title="Workers" />
          {data.length ? (
            <>
              <WorkersTable workers={data} />
              {hasMore && (
                <Button center onClick={fetchMore}>
                  More
                </Button>
              )}
            </>
          ) : (
            'No search results'
          )}
        </>
      )}
    </Loader>
  )
}

export default WorkersContainer
