import styled from 'styled-components'

const Title = styled.h1`
  font-size: 32px;
  letter-spacing: -1px;
  margin-bottom: 25px;
`

const SubTitle = styled.h2`
  font-size: 24px;
  letter-spacing: -0.8px;
  margin-bottom: 0;

  ${p => p.theme.layout.tablet} {
    margin-bottom: 8px;
  }
`

export { Title, SubTitle }
