import React from 'react'

import { usePaginatedFetch } from '../../utilities'
import HoldingTable from './holding'
import SearchForm from '../search-form'
import Loader from '../loader'
import { Button } from '../../ui'

// Container to fetch holding data
const HoldingContainer = () => {
  const {
    error,
    isFetching,
    data,
    search,
    fetchMore,
    hasMore,
  } = usePaginatedFetch('/admin/holding')

  // search term used to fetch data from api, results update data
  const handleSearch = query => {
    search(query)
  }

  // return 'Users in holding will be displayed here'
  return (
    <Loader isFetching={isFetching} error={error}>
      {data && (
        <>
          <SearchForm onSearch={handleSearch} title="Holding" />
          {data.length ? (
            <>
              <HoldingTable holding={data || []} />
              {hasMore && (
                <Button center onClick={fetchMore}>
                  More
                </Button>
              )}
            </>
          ) : (
            'No search results'
          )}
        </>
      )}
    </Loader>
  )
}

export default HoldingContainer
